import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h2>
    <p>{`Assessment is a process concerned with gathering information about student competencies and planning to promote future learning. It’s focus is on, not only on what students have achieved but also on the processes they have used to reach their goals as well as their perceptions of their own success. Relevant, meaningful, measurable assessment is designed to inform and improve the programme of learning and teaching as well as student outcomes. Assessment is also aimed at promoting a positive attitude towards learning whilst encouraging the pursuit of excellence. It should enable teachers to improve their planning of effective teaching strategies and learning situations.`}</p>
    <h2 {...{
      "id": "basis-of-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#basis-of-assessment",
        "aria-label": "basis of assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Basis of Assessment`}</h2>
    <p>{`The assessment process is based on goals and the work students must do to meet those goals. It ensures that there are clear expectations about the nature of the learning to be undertaken and the ways it will be assessed. This means:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Involving students in setting attainable goals and identifying means of assessment`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Expressing goals in terms of the tasks`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Continuous assessment of students as they work towards their goals`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Focusing on what students have achieved and on how they can improve their performance`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Asking students to assess their own work`}</p>
      </li>
    </ul>
    <p>{`Assessment is integral to all learning. It is central to the PYP’s goal of thoughtfully and effectively guiding children through the five essential elements of learning: the understanding of concepts, the acquisition of knowledge, the mastering of skills, the development of positive attitudes, and the decision to take responsible action.`}</p>
    <p>{`Everyone involved in the learning process of students (students, teachers, parents, administrators) must have a clear understanding of the reasons for assessment, what is being assessed, the criteria for success and the method by which the assessment is made.`}</p>
    <h2 {...{
      "id": "collecting-assessment-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#collecting-assessment-data",
        "aria-label": "collecting assessment data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Collecting Assessment Data`}</h2>
    <p>{`The PYP aims to thoughtfully and effectively guide children through five essential elements of learning:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`the understanding of concepts`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the acquisition of knowledge`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the mastering of skills`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the development of positive attitudes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`the decision to take responsible action`}</p>
      </li>
    </ul>
    <p>{`Because we consider these elements essential to our programme, we need to explicitly assess student progress in each of these elements in all subject areas.`}</p>
    <p>{`Throughout the learning process, both students and teachers are actively involved in assessing student progress. Students will regularly be asked to assess their own work and to reflect on their learning process. Teachers will support students in assessing their own work by providing assessment criteria and by modelling the assessment process. Assessment criteria as phrased in performance lists or in rubrics should be established in collaboration with the students and should guide both feedback sessions between the teacher and the student and student self assessment. The assessment criteria should be accessible for the students while they are working towards a product.`}</p>
    <h2 {...{
      "id": "assessment-terms-and-practices",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assessment-terms-and-practices",
        "aria-label": "assessment terms and practices permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assessment terms and practices`}</h2>
    <h4 {...{
      "id": "pre-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#pre-assessment",
        "aria-label": "pre assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Pre-Assessment`}</h4>
    <ul>
      <li parentName="ul">{`At the beginning of each Unit of Inquiry, teachers will assess children’s prior knowledge and experience before embarking on new learning experiences. This includes stand-alone and integrated maths and language studies as well.`}</li>
    </ul>
    <h4 {...{
      "id": "formative-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#formative-assessment",
        "aria-label": "formative assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Formative Assessment`}</h4>
    <ul>
      <li parentName="ul">{`Ongoing and regular assessment used during the learning and teaching process to inform teachers and children about how learning is developing. Formative assessment and teaching are directly linked. A variety of methods are used.`}</li>
    </ul>
    <h4 {...{
      "id": "summative-assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#summative-assessment",
        "aria-label": "summative assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Summative assessment`}</h4>
    <ul>
      <li parentName="ul">{`Summative assessment is assessment that takes place at the end of a learning and teaching process. This is planned in advance. The assessment is designed so that through it, students can show their understanding in authentic contexts and apply this in new and flexible ways.`}<br />{` We believe that assessment is most effective when it is integrated into and generated from the on-going teaching activities that take place in the class.`}</li>
    </ul>
    <h4 {...{
      "id": "assessment-strategies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#assessment-strategies",
        "aria-label": "assessment strategies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assessment strategies:`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Observations`}</strong>{`: All children are observed informally, with the teacher taking notes on the individual, the group, and the whole class. Observations include how groups work and the roles of participants within the group.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Process focus:`}</strong>{` Students’ skills and developing understanding are observed in real contexts, wherever possible, using checklists, narrative notes and inventories. The emphasis is on the process and skill application rather than the product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Selected responses:`}</strong>{` Single ‘snapshot’ samples of what students know or are able to do.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Open Ended tasks:`}</strong>{` Students are given a stimulus and asked to communicate an original response. No two students will have the same response. In this way, the student’s understanding and application can be individually assessed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Student Portfolios:`}</strong>{` Teachers and students maintain a portfolio of work that demonstrates growth, thinking, creativity and reflection for both academic and social development over time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Performance Tasks:`}</strong>{` Represents the kind of challenges that adults face in the world beyond the classroom, requires using a repertoire of knowledge and skill to accomplish a goal or solve a problem, requires thoughtfully applying knowledge rather than recalling facts, is open ended, developing a tangible product or performance, has an identified purpose and audience, involves a realistic scenario, and involves criteria to be met.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "recording-assessment-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#recording-assessment-data",
        "aria-label": "recording assessment data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Recording Assessment Data`}</h2>
    <p>{`All teachers at PSI have a recording system. A recording system may include record sheets on which teachers write narrative comments on student performance. In whichever form the teacher feels most effective for them, assessment data available on students must be recorded. In addition, it is possible that assessment data is available in other places, e.g., teacher comments which are included in the portfolio or are written on student products.`}</p>
    <h2 {...{
      "id": "assessment-tools",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assessment-tools",
        "aria-label": "assessment tools permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assessment Tools`}</h2>
    <p>{`Assessment tools are used to derive meaning from the assessment data generated through the activities described under the Assessment Strategies listed previously. Most assessment tools describe how well a student is doing and help to determine the progress a student has made.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Performance Lists:`}</strong>{` Performance lists offer a practical means of judging student performance based upon identified criteria. A performance list consists of a set of criterion elements, but not performance levels. These are used for students to know the criteria before embarking on their work, to assess that they met the criteria. These do not measure how well the students performed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Holistic Rubrics:`}</strong>{` A holistic rubric provides an overall impression of a student’s work and yields a single score or rating for a product or performance. They provide a quick snapshot or impact of overall performance. These are used for performances or pieces of work that address a combination of criteria.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Analytic Rubrics:`}</strong>{` An analytic rubric divides a product or performance into distinct traits or dimensions and judges each separately. Since an analytic rubric rates each of the identified traits independently, and a separate score is provided for each. These are used for larger projects and summative assessments as well as for analysing writing.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Exemplars:`}</strong>{` These are samples of student work that serve as concrete standards against which other samples are judged. These can be used in conjunction with rubrics or continuums.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Anecdotal Notes:`}</strong>{` Anecdotal notes are written notes based on an observation of children. These notes need to be systematically compiled, organised and analysed.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Continuums:`}</strong>{` These are a visual representation of developmental stages of learning. They show a progression of achievement or identify where a child is in a process.`}</p>
      </li>
    </ul>
    <p>{`The reporting of the results of our assessments is holistic. Reporting focuses on process as well as product and relates the assessment results to both the curriculum and the learning contexts.`}</p>
    <h2 {...{
      "id": "reporting-assessment-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#reporting-assessment-data",
        "aria-label": "reporting assessment data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reporting Assessment Data`}</h2>
    <p>{`When writing written reports and preparing for Parent Teacher Conferences, teachers consult and use their recording system. In written reports and in Parent Teacher Conferences, specific reference will be made to student performance as evidenced in written work, discussion or behaviour. PSI maintains a policy of reporting the findings of assessment through:`}</p>
    <h2 {...{
      "id": "parent-teacher-conferences",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#parent-teacher-conferences",
        "aria-label": "parent teacher conferences permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Parent-Teacher Conferences`}</h2>
    <p>{`Parent Teacher conferences will take place every year in November where the teachers and parents meet to discuss the student’s progress up to that point in time. Data collected will include school tasks and assignments, MAP tests, DRA and/or WIDA, and JAM or GLoSS data. Following the end of semester 1 reports a PTC (Parent Teacher Conference) is scheduled. At this time teachers will discuss student performance and behaviour in the class. Parents will also be able to provide their perspective. Additional parent teacher conferences may be scheduled upon request of either the parents or the teacher.`}</p>
    <h2 {...{
      "id": "seesaw",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#seesaw",
        "aria-label": "seesaw permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Seesaw`}</h2>
    <p>{`Seesaw is an online platform that allows teachers, parents and students to send, receive and observe purposeful collections of students’ work. It also allows us to keep a digital record of that work so that, throughout the year, students are able to regularly look back at their work, select products that show progress and reflect on them. This assists the students in becoming self-assessors and to develop critical thinking skills. The teacher will offer advice regarding the selection of student products and may select some products. The teacher will add descriptions of the context in which the student product was generated, an explanation of the task, the link to the curriculum as well as their own comments on the product. In addition, general reflections, photographs illustrating processes and other evidence of student progress may be included. A selection of work in the student portfolio should be annotated with a student reflection indicating what the work shows and why it was chosen. All products in the portfolio should be dated. The student and teacher are responsible for regular additions to and revision of the portfolio.`}</p>
    <p>{`The student portfolio will include self assessments, draft and final form exemplars, reflections and samples to show growth over time. Teachers will be responsible for monitoring student portfolio content, both for the teacher selected and student-selected entries.`}</p>
    <h2 {...{
      "id": "student-led-conferences",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#student-led-conferences",
        "aria-label": "student led conferences permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Student-Led Conferences`}</h2>
    <p>{`The Student-Led Conference is a meeting in which the students use their portfolio as a basis for discussion with their parents about their learning. The conferences allow students to play an active role in the reporting process and communicate their learning to their parents. The teacher will usually not participate in the Student-Led Conference. The teacher may be present as a facilitator, if necessary, but not as the reporting authority. At the end of the conference, the parent and the student may fill in a form together that describes strengths, goals and strategies.`}</p>
    <p>{`Student-Led conferences are officially scheduled in the spring. However, teachers may offer parents the opportunity to view portfolios at other times during the school year, such as after the completion of a Unit of Inquiry.`}</p>
    <h2 {...{
      "id": "sharing-our-learning-events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sharing-our-learning-events",
        "aria-label": "sharing our learning events permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sharing Our Learning Events`}</h2>
    <p>{`PSI Primary School believes that it is important for parents to be able to see their child’s work throughout the school year. Therefore, throughout the course of the school year, teachers will invite parents to celebrate the students’ learning. This may take many forms- for example it may be in the form of an author celebration at the end of a writing unit where students wrote their own stories, an art gallery where students show parents how they express themselves through art, or science experiments at the end of a unit of inquiry.`}</p>
    <h2 {...{
      "id": "written-reports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#written-reports",
        "aria-label": "written reports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Written Reports`}</h2>
    <p>{`Formal report cards are uploaded to MyPSI at the end of Semester 1 (January / February) and then at the end of the academic year in June. All teachers contribute to these comprehensive reports.`}</p>
    <h2 {...{
      "id": "kindergarten--grade-5-school-assessments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#kindergarten--grade-5-school-assessments",
        "aria-label": "kindergarten  grade 5 school assessments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kindergarten – Grade 5 School Assessments:`}</h2>
    <p><strong parentName="p">{`Mathematics:`}</strong>{` Beginning in the 2021 - 2022 school year, the PSI Primary School will administer the JAM and GLoSS maths assessment for Kindergarten through Grade 5 students in the fall and spring of each school year. These assessments are administered in a 1:1 capacity by the homeroom teachers and assists us in defining the stage of mathematics development the student is in. They also allow us to determine the areas of strength and areas of misconception a student may have as well as progress throughout the year.`}</p>
    <p><strong parentName="p">{`Literacy:`}</strong>{` Our Diagnostic Reading Assessment (DRA2) allows us to determine the level of independent and instructional reading of each student, which in turn allows teachers and students to choose books and text that are at just the right level of learning for the student. These assessments are administered to each child in a 1:1 capacity and by the homeroom teacher and given in the fall and spring of each school year. Teachers are able to determine the progress of a student’s reading as well as their areas of strength and what strategies the student needs to improve upon.`}</p>
    <h2 {...{
      "id": "standardised-testing-of-student-ability",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#standardised-testing-of-student-ability",
        "aria-label": "standardised testing of student ability permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Standardised Testing of Student Ability`}</h2>
    <p>{`In order to ensure the validity of the school’s programme, PSI engages in the external assessment of students and analysis of those results. These measures include:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Measure of Academic Progress (MAP) is an online test for students in Grades 3 - 8 in Mathematics and English reading. Students take the test in the fall and the spring of each academic year. The test is interactive - i.e., adjusts the questions based on student responses. Students are marked against set criteria and their progress is charted. The school receives data on individual student progress, both in terms of measures of progress and percentile rankings comparing them to other students worldwide who take the same test.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The Learning Support (LS) teacher does some academic and behavioural testing for students who have been identified as at risk. When the need arises, the school also brings in a qualified psychologist to provide additional testing, at the parent expense. In all cases, results are shared with parents and the psychologist gives the teaching staff recommendations of accommodations that can be made for each student. With written parent permission, specific test results are shared with appropriate staff.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Reading levels are tested through the Diagnostic Reading Assessment (DRA2) system (Grades K - 5) as well as with the MAP Lexile score (Grades 3 - 5).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The WIDA test is administered to students who are not native English speakers. This test measures the student’s proficiency in reading, writing, speaking and listening in English.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Mathematics understanding and progression is evaluated through the JAM and GLoSS assessments in Grades K - 5. These assessments are administered every year in the fall and spring and allows the teacher to determine where a child is in her conceptual understanding of mathematics and what areas of mathematics the child may need more support on or more extension in.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "the-exhibition-making-thinking-visible",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#the-exhibition-making-thinking-visible",
        "aria-label": "the exhibition making thinking visible permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Exhibition: Making thinking visible`}</h2>
    <p>{`What do diving, poverty, dancing, pollution, cooking and obesity have in common? Well, in the Grade 5 classes at PSI, they are some of the passions and global issues that students have identified during the course of the Exhibition Unit of Inquiry. The Exhibition gives the Grade 5 students a chance to identify a personal passion, connect it to a global issue, and then take action at the local level to help make a difference. In essence, it is a chance for the Grade 5 students to show what they have learnt about what it means to be a student in the PYP at PSI.`}</p>
    <p>{`This unit begins when students are asked to identify a personal passion.  Time is spent in class defining what a passion is, and then students narrow the things they like to do down to those things that they love to do.  Students spend some time exploring their passions as well as finding information about others who have similar passions.`}</p>
    <p>{`The next step along this journey is for students to make a connection between their passion and an issue facing the world. Students spend some time looking at major issues facing the world and discuss how they are connected to the passions they have identified. Once that important connection is made, students are ready to research the issue and develop a written report on what they learn.`}</p>
    <p>{`The third stage involves developing a local action that connects to the global issue.  Students are asked what they could do locally that could help make a difference in their community. They form action groups based on similar global issues and brainstorm a myriad of ideas about the types of action they could take.  This is a very exciting time in a PYP school and students always amaze us with their ideas: from finding guest speakers to creating museums and treasure hunts; from creating lessons for younger children to supporting organisations or planting trees.`}</p>
    <p>{`The final stage is where all of the work and the learning journey is shared with parents and the school community.  It is a chance for the students to show what they were able to accomplish independently.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      